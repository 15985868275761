import axios from "axios";
import formats from "../utils/formats";
import restaurantClient from "./restaurant";

const RESTAURANT_JSON_ENDPOINT = "https://mycia-mcm.s3.eu-central-1.amazonaws.com/:token/:lang/restaurant.json";

let restaurantCache = null;

async function fetchRestaurantData(lang, token) {
    if (restaurantCache) {
        return restaurantCache;
    }

    try {
        const response = await axios.get(formats.formatUrl(RESTAURANT_JSON_ENDPOINT, { token, lang }));
        restaurantCache = response.data;
        return restaurantCache;
    } catch (error) {
        throw error;
    }
}

async function doGet(lang, token) {
    try {
        const restaurantData = await fetchRestaurantData(lang, token);

        return restaurantData.data;
    } catch {
        // Fallback to legacy restaurantClient
        return await restaurantClient.doGet(lang, token);
    }
}


const restaurantV2 = {
    doGet,
};

export default restaurantV2;
