import axios from "axios";
import formats from "../utils/formats";
import coursesClient from "./courses";

const COURSES_AWS_ENDPOINT = "https://mycia-mcm.s3.eu-central-1.amazonaws.com/:token/:lang/courses.json";

let coursesCache = null;

async function fetchCoursesData(lang, restaurantToken) {
  if (coursesCache) {
    return coursesCache;
  }

  try {
    const response = await axios.get(formats.formatUrl(COURSES_AWS_ENDPOINT, { lang, token: restaurantToken }));
    coursesCache = response.data;
    return coursesCache;
  } catch (error) {
    throw error;
  }
}

async function doGet(lang, restaurantToken, id) {
  try {
    const courses = await fetchCoursesData(lang, restaurantToken);
    const course = courses.data.find(
      (course) => course.id.toString() === id.toString()
    );

    if (course) {
      return course;
    }
  } catch (error) {
    // Fallback to legacy coursesClient
    return await coursesClient.doGet(lang, id);
  }
}

const coursesClientV2 = {
  doGet,
};

export default coursesClientV2;
