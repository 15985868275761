import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import theme from '../../utils/theme';
import formats from '../../utils/formats';
import { useUser } from "../../utils/contexts/user-context";
import { useTracker } from '../../utils/contexts/tracker-context';
import { calendarOutline } from 'ionicons/icons';
import TackpayFollowUpModal from '../modals/TackpayFollowUpModal';

export default function Splash(props) {
    const { restaurant } = props;

    const { t } = useTranslation();
    const { type, id } = useParams();
    const { trackDataFn } = useTracker();
    const { user } = useUser();
    const history = useHistory();

    const [showTackpayInfoModal, setShowTackpayInfoModal] = React.useState(false);

    function tackpayRouting() {
        if (restaurant.tackpay_bucket_id) {
            const success_url = `${window.location.origin}/${type}/q/${id}/tip/success`;
            const tackPayUrl = `${process.env.REACT_APP_TACKPAY}/checkout/${restaurant.tackpay_bucket_id}?&success_url=${success_url}&error_url=error_url`;

            window.open(tackPayUrl, '_blank');
        } else {
            setShowTackpayInfoModal(true);
        }
    }

    React.useLayoutEffect(() => {
        let params = new URLSearchParams(window.location.search);

        async function track(action, user) {
            await trackDataFn(restaurant.id, restaurant.token, action, { token: user.token }, restaurant.has_orders);
        }

        if (restaurant && user && params.has("newcia")) {
            track("newcia", user);
        }

        if (restaurant && user && params.has("logincia")) {
            track("logincia", user);
        }
    }, [restaurant, trackDataFn, user]);

    async function onClickHandler(path) {
        await trackDataFn(restaurant.id, restaurant.token, "pageview", { "type": type }, restaurant.has_orders);

        if (path === "menu") {
            history.push(formats.formatUrl("/:type/q/:id/menu", { "type": type, "id": id }));
        } else if (path === "booking") {
            history.push(formats.formatUrl("/:type/q/:id/bookingrestaurant", { "type": type, "id": id }));
        } else if (path === "info") {
            history.push(formats.formatUrl("/:type/q/:id/info", { "type": type, "id": id }));
        }
    }

    return (
        <div className="splash h-screen flex flex-col content-stretch">
            <img src={restaurant.image_path} alt={t("splash.img_1")} className="w-full" />

            <div className="relative py-6 px-4 text-center flex flex-col grow">
                <div>
                    <h1 className="mb-2 text-4xl">{restaurant.name}</h1>

                    {process.env.REACT_APP_NAMESPACE !== "shop" && restaurant.has_booking === true ?
                        <div className="pb-6 underline text-mycia-blue cursor-pointer" onClick={() => onClickHandler("info")}>informazioni sul locale</div>
                        : null}

                    <button type="button" onClick={() => onClickHandler("menu")} className="block w-full cursor-pointer py-3 px-8 rounded-md font-medium bg-mycia-blue text-white">
                        {process.env.REACT_APP_NAMESPACE === "shop" ? "SCOPRI I PRODOTTI" : theme.getCustomElement(restaurant.id, "splash", t)}
                    </button>

                    {restaurant.has_tackpay === true &&
                        <button type="button" onClick={() => tackpayRouting()} className="block relative w-full cursor-pointer mt-4 py-3 rounded-md font-medium bg-[#93E6A2] text-white uppercase">
                            <img src="/assets/images/tip.png" className="absolute left-3 inline w-8" alt="tip icon" />
                            {t("tip.button_1")}
                        </button>}

                    {restaurant.has_login ?
                        !user ?
                            <div className="mt-4">
                                <a href={formats.loginUrl(type, id)}
                                    className="block w-full my-4 cursor-pointer py-3 px-8 rounded-md font-medium bg-mycia-red text-white">
                                    Accedi con Carta d'Identità Alimentare
                                </a>

                                <p className="mt-2">
                                    Da oggi utilizza la tua <span className="underline font-bold">Carta d'Identità Alimentare</span>® nei menù digitali! Potrai vedere ogni menù di MyCIA filtrato secondo i tuoi gusti e preferenze.
                                    Se non possiedi un account puoi crearlo gratuitamente in pochi minuti <a href={`https://login.mycia.it/register?redirect=${encodeURIComponent(formats.mcmUrl(type, id))}`} target="_blank" className="underline text-mycia-red" rel="noreferrer">cliccando qui</a>.
                                </p>
                            </div> :
                            <div className="w-full my-4 mx-auto py-3 px-3 text-slate-600">
                                <h3>Stai utilizzando la Carta d'Identità Alimentare di</h3>
                                <p className="text-2xl">{user.name} {user.lastname}</p>
                            </div> :
                        null}
                </div>

                <div className="flex flex-col-reverse grow">
                    {process.env.REACT_APP_NAMESPACE !== "shop" && restaurant.has_booking === true ?
                        <button type="button" onClick={() => onClickHandler("booking")} className="my-8 w-full flex items-center justify-center cursor-pointer py-3 px-8 rounded-md font-medium border-2 border-mycia-blue">
                            <IonIcon className="mr-3 w-6" icon={calendarOutline} />
                            <p>PRENOTA UN TAVOLO</p>
                        </button> : null}
                </div>
            </div>
            <TackpayFollowUpModal showModal={showTackpayInfoModal} setShowModal={setShowTackpayInfoModal} />
        </div>
    );
}
